

// Chakra imports
import { Avatar, Box, Flex, FormLabel, Icon, Select, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
// Assets
import Usa from 'assets/img/dashboards/usa.png';
// Custom components
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import {
	MdAddTask,
	MdAttachMoney,
	MdBarChart, MdDiversity3,
	MdFileCopy, MdGeneratingTokens,
	MdGroup,
	MdGroups, MdHub, MdLocalFireDepartment, MdMilitaryTech,
	MdOutlineGroups, MdOutlineHub,
	MdPerson,
	MdPerson2, MdRocket, MdRocketLaunch, MdTouchApp
} from 'react-icons/md';
import CheckTable from 'views/admin/rtl/components/CheckTable';
import ComplexTable from 'views/admin/default/components/ComplexTable';
import DailyTraffic from 'views/admin/default/components/DailyTraffic';
import PieCard from 'views/admin/default/components/PieCard';
import Tasks from 'views/admin/default/components/Tasks';
import TotalSpent from 'views/admin/default/components/TotalSpent';
import WeeklyRevenue from 'views/admin/default/components/WeeklyRevenue';
import tableDataCheck from 'views/admin/default/variables/tableDataCheck';
import tableDataComplex from 'views/admin/default/variables/tableDataComplex';
import UserService from "../../../service/user.service";
import {useState} from "react";
import {useSelector} from "react-redux";
import {increasePercentage, shortNum} from "../../../helper/format.helper";

export default function UserReports() {
	const user: UserSliceType = useSelector((state: any) => state.user)
	const coin: CoinSliceType = useSelector((state: any) => state.coin)
	const item: ItemSliceType = useSelector((state: any) => state.item)

	const minedToday = BigInt(coin.tapEarnToday ?? 0) + BigInt(coin.rewardEarnToday ?? 0);
	const minedYesterday = BigInt(coin.tapEarnYesterday ?? 0) + BigInt(coin.rewardEarnYesterday ?? 0);

	// Chakra Color Mode
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<SimpleGrid columns={{ base: 1, md: 2, lg: 5, '2xl': 6 }} gap='20px' mb='20px'>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdGroups} color={brandColor} />}
						/>
					}
					name='Users'
					growth={shortNum(user.joinedToday)}
					growthText={'Joined today'}
					value={user.totalLength}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdOutlineGroups} color={brandColor} />}
						/>
					}
					name='Daily Active'
					value={user.dailyOnline}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdTouchApp} color={brandColor} />}
						/>
					}
					growth={increasePercentage(minedYesterday, BigInt(minedYesterday + minedToday))} name='Mined Today' value={shortNum(BigInt(coin.tapEarnToday ?? 0) + BigInt(coin.rewardEarnToday ?? 0))} />
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdOutlineHub} color={brandColor} />}
						/>
					}
					name='Total Mined'
					value={shortNum(BigInt(coin.totalEarned))}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdLocalFireDepartment} color={brandColor} />}
						/>
					}
					name='Total Spent'
					value={shortNum(BigInt(coin.totalSpent))}
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdRocket} color={brandColor} />}
						/>
					}
					name='Booster' value={shortNum(item.boostLength)} />
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdRocketLaunch} color={brandColor} />}
						/>
					}
					name='Daily Boosts' value={shortNum(item.dailyBoostLength)} />
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdMilitaryTech} color={brandColor} />}
						/>
					}
					name='Leagues' value={shortNum(item.leagueLength)} />
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdGeneratingTokens} color={brandColor} />}
						/>
					}
					name='Skins' value={shortNum(item.skinLength)} />
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdDiversity3} color={brandColor} />}
						/>
					}
					name='Squads' value={shortNum(item.squadLength)} />
			</SimpleGrid>

			<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
				<DailyTraffic />
				<PieCard />
			</SimpleGrid>
			<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
				{item.latestTransactions.length > 0 ? <CheckTable tableData={item.latestTransactions} /> : null}
			</SimpleGrid>
		</Box>
	);
}
