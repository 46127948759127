import {createSlice} from "@reduxjs/toolkit";
import TokenService from "../service/core/token.service";

const user = TokenService.getUser();
const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuth: !!user,
        accessToken: user ? TokenService.getLocalAccessToken() : '',
        refreshToken: user ? TokenService.getLocalRefreshToken() : '',
        storage: TokenService.storage,
        accessCheck: null
    } as AuthSliceType,
    reducers: {
        login: (state) => {
            state.isAuth = true;
        },
        setToken: (state, action) => {
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
        },
        refreshToken: (state, action) => {
            state.accessToken = action.payload.accessToken;
        },
        logout: (state) => {
            state.isAuth = false;
            state.accessToken = '';
            state.refreshToken = '';
            TokenService.removeUser();
        },
        setAccessCheck: (state, action) => {
            state.accessCheck = action.payload;
        }
    }
})

export const {login, setToken, refreshToken, logout, setAccessCheck} = authSlice.actions;
export default authSlice.reducer;