import {createSlice} from "@reduxjs/toolkit";

const coinSlice = createSlice({
    name: 'coin',
    initialState: {
        totalPlayers: '1',
        dailyPlayers: 0,
        totalEarned: '1',
        totalSpent: '1',
        tapEarn: 0,
        tapEarnToday: 0,
        tapEarnYesterday: 0,
        rewardEarn: 0,
        rewardEarnToday: 0,
        rewardEarnYesterday: 0,
    } as CoinSliceType,
    reducers: {
        setCoin(state, action) {
            state.totalPlayers = action.payload.totalPlayers;
            state.dailyPlayers = action.payload.dailyPlayers;
            state.totalEarned = action.payload.totalEarned;
            state.totalSpent = action.payload.totalSpent;

            state.tapEarn = action.payload.tapEarn;
            state.tapEarnToday = action.payload.tapEarnToday;
            state.tapEarnYesterday = action.payload.tapEarnYesterday;
            state.rewardEarn = action.payload.rewardEarn;
            state.rewardEarnToday = action.payload.rewardEarnToday;
            state.rewardEarnYesterday = action.payload.rewardEarnYesterday;
        }
    }
})


export const {setCoin} = coinSlice.actions;
export default coinSlice.reducer;