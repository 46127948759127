import api from "./core/api";
import TokenService from "./core/token.service";


class AuthService {
    login(username: string, password: string) {
        return api
            .post("/auth/login", {
                username,
                password
            })
            .then(response => {
                if (response.data.accessToken) {
                    TokenService.setUser(response.data);
                }
                return response.data;
            });
    }

    logout() {
        TokenService.removeUser();

        return api.get('/auth/logout');
    }

    register(username: string, email: string, password: string) {
        return api.post("/auth/signup", {
            username,
            email,
            password
        });
    }
}

export default new AuthService();