import {configureStore} from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import activeRouteSlice from "./activeRouteSlice";
import adminSlice from "./adminSlice";
import userSlice from "./userSlice";
import coinSlice from "./coinSlice";
import itemSlice from "./itemSlice";

export const store = configureStore({
    reducer: {
        auth: authSlice,
        activeRoute: activeRouteSlice,
        admin: adminSlice,
        user: userSlice,
        coin: coinSlice,
        item: itemSlice
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
})