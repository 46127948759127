import axiosInstance from "./api";
import TokenService from "./token.service";
import {logout, refreshToken, setAccessCheck} from "../../store/authSlice";
import {toast} from "sonner";
import AdminService from "../admin.service";
import AuthService from "../auth.service";

const setup = (store: any) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            console.log("Request ...");
            const token = TokenService.getLocalAccessToken();
            const rToken = TokenService.getLocalAccessToken();
            if (token) {
                config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
                // config.headers["x-access-token"] = token; // for Node.js Express back-end
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    const {dispatch} = store;
    axiosInstance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            if (TokenService.getLocalRefreshToken() == null) {
                return Promise.reject(err);
            }
            const originalConfig = err.config;
            if (originalConfig.url !== "/auth/login" && err.response) {
                // Access Token was expired
                if (err.response.status === 403 && !originalConfig._retry) {
                    console.log("Access Token was expired, trying to refresh ...");
                    originalConfig._retry = true;

                    try {
                        const rs = await axiosInstance.post("/auth/refreshToken", {
                            refreshToken: TokenService.getLocalRefreshToken(),
                        })

                        const {accessToken} = rs.data;

                        dispatch(refreshToken(accessToken));
                        TokenService.updateLocalAccessToken(accessToken);

                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        return Promise.reject(_error);
                    }
                }
                if (err.response.status === 401 && originalConfig.url === "/auth/refreshToken") {
                    clearInterval(store.getState().auth.accessCheck);
                    dispatch(setAccessCheck(null));
                    await AuthService.logout().catch((e) => {})
                    dispatch(logout());
                    toast.error("Your session has expired, please login again");
                }
            }
            return Promise.reject(err);
        }
    );
};

export default setup;