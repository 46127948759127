import {useEffect, useState} from 'react';
import {Outlet, Route, useNavigate} from 'react-router-dom';
import routes from 'routes';

// Chakra imports
import { Box, useColorModeValue } from '@chakra-ui/react';

// Layout components
import { SidebarContext } from 'contexts/SidebarContext';
import {refreshRoutes} from "../../store/activeRouteSlice";
import {useSelector} from "react-redux";

// Custom Chakra theme
export default function Auth() {
	// states and functions
	const auth = useSelector((state: any) => state.auth);
	const navigate = useNavigate();
	const [ toggleSidebar, setToggleSidebar ] = useState(false);
	const authBg = useColorModeValue('white', 'navy.900');
	document.documentElement.dir = 'ltr';

	useEffect(() => {
		if (auth.isAuth) {
			navigate('/admin/default')
		}
	}, [auth.isAuth]);
	return (
		<Box>
			<SidebarContext.Provider
				value={{
					toggleSidebar,
					setToggleSidebar
				}}>
				<Box
					bg={authBg}
					float='right'
					minHeight='100vh'
					height='100%'
					position='relative'
					w='100%'
					transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
					transitionDuration='.2s, .2s, .35s'
					transitionProperty='top, bottom, width'
					transitionTimingFunction='linear, linear, ease'>
					<Outlet />
				</Box>
			</SidebarContext.Provider>
		</Box>
	);
}
