import {createSlice} from "@reduxjs/toolkit";

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        id: '',
        name: '',
        username: '',
        user_id: '',
        status: false,
        createdAt: '',
    },
    reducers: {
        setAdmin: (state, action) => {
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.username = action.payload.username;
            state.user_id = action.payload.user_id;
            state.createdAt = action.payload.createdAt;
            state.status = true;
        }
    }
})


export const {setAdmin} = adminSlice.actions;
export default adminSlice.reducer;