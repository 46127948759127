import {createBrowserRouter, Navigate, RouterProvider} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import SignInCentered from "./views/auth/signIn";
import Profile from "./views/admin/profile";
import NFTMarketplace from "./views/admin/marketplace";
import DataTables from "./views/admin/dataTables";
import MainDashboard from "./views/admin/default";
import AdminLayout from "./layouts/admin";
import {useEffect} from "react";
import {useSelector} from "react-redux";

const App = () => {
    const routes = createBrowserRouter([
        // on / redirect to /admin
        {
            index: true,
            element: <Navigate to="/admin/default" replace />
        },
        {
            path: 'auth',
            element: <AuthLayout/>,
            children: [
                {
                    index: true,
                    element: <Navigate to="/auth/sign-in" replace />
                },
                {
                    path: 'sign-in',
                    element: <SignInCentered/>
                }
            ]
        },
        {
            path: 'admin',
            element: <AdminLayout/>,
            children: [
                {
                    index: true,
                    element: <Navigate to="/admin/default" replace />
                },
                {
                    path: 'default',
                    element: <MainDashboard/>
                },
                {
                    path: 'profile',
                    element: <Profile/>
                },
                {
                    path: 'data-tables',
                    element: <DataTables/>
                },
                {
                    path: 'nft-marketplace',
                    element: <NFTMarketplace/>
                },
            ]
        },
    ], {basename: '/'})
    const auth = useSelector((state: any) => state.auth);

    return (
        <RouterProvider router={routes}/>
    );
};

export default App;