class TokenService {
    public storage: Storage = sessionStorage; // localStorage or sessionStorage
    getLocalRefreshToken() {
        const user = JSON.parse(this.storage.getItem("user"));
        return user?.refreshToken;
    }

    getLocalAccessToken() {
        const user = JSON.parse(this.storage.getItem("user"));
        return user?.accessToken;
    }

    updateLocalAccessToken(accessToken: string) {
        let user = JSON.parse(this.storage.getItem("user"));
        user.accessToken = accessToken;
        this.storage.setItem("user", JSON.stringify(user));
    }

    getUser() {
        return JSON.parse(this.storage.getItem("user"));
    }

    setUser(user: TokenType) {
        console.log(JSON.stringify(user));
        this.storage.setItem("user", JSON.stringify(user));
    }

    removeUser() {
        this.storage.removeItem("user");
    }
}

export default new TokenService();