export const shortNum = (num: bigint | 0 | number): string => {
    const lookup: object[] = [
        {value: 1, symbol: ""},
        {value: 1e3, symbol: "k"},
        {value: 1e6, symbol: "M"},
        {value: 1e9, symbol: "B"},
        {value: 1e12, symbol: "T"},
        {value: 1e15, symbol: "Qa"},
        {value: 1e18, symbol: "Qi"},
        {value: 1e21, symbol: "Sx"},
        {value: 1e24, symbol: "Sp"},
        {value: 1e27, symbol: "O"},
        {value: 1e30, symbol: "N"},
        {value: 1e33, symbol: "D"},
        {value: 1e36, symbol: "Ud"},
        {value: 1e39, symbol: "Dd"},
        {value: 1e42, symbol: "Td"},
        {value: 1e45, symbol: "Qad"},
        {value: 1e48, symbol: "Qid"},
        {value: 1e51, symbol: "Sxd"},
        {value: 1e54, symbol: "Spd"},
        {value: 1e57, symbol: "Od"},
        {value: 1e60, symbol: "Nd"},
        {value: 1e63, symbol: "V"},
        {value: 1e66, symbol: "Uv"},
        {value: 1e69, symbol: "Dv"},
        {value: 1e72, symbol: "Tv"},
        {value: 1e75, symbol: "Qiv"},
        {value: 1e78, symbol: "Qiv"},
        {value: 1e81, symbol: "Sxv"},
        {value: 1e84, symbol: "Spv"},
        {value: 1e87, symbol: "Ov"},
        {value: 1e90, symbol: "Nv"},
        {value: 1e93, symbol: "Tg"},
        {value: 1e96, symbol: "Utg"},
        {value: 1e99, symbol: "Dtg"},
        {value: 1e102, symbol: "Trtg"},
        {value: 1e105, symbol: "Qttg"},
        {value: 1e108, symbol: "Qntg"},
        {value: 1e111, symbol: "Sxtg"},
        {value: 1e114, symbol: "Sptg"},
        {value: 1e117, symbol: "Ot"},
    ];
    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
    // @ts-ignore
    const item = lookup.findLast((item: any) => num >= item.value);
    return item ? (BigInt(num) / BigInt(item.value)).toLocaleString().replace(regexp, "").concat(item.symbol) : "0";
}

export const increasePercentage = (old: bigint, newNum: bigint, mark?: boolean): string => {
    old = old == BigInt(0) ? BigInt(1) : old;
    const increase = newNum - old;
    const percentage = (Number(increase) / Number(old)) * Number(100);
    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
    return (percentage > 0 && (mark == undefined || mark == true) ? ('+' + insureNumber(Number(percentage)).toFixed(2).replace(regexp, "")) : insureNumber(Number(percentage)).toFixed(2).replace(regexp, "")) + "%";
}

export const percentage = (old: bigint | number, newNum: bigint | number): string => {
    // alert(old)
    const percentage = Number(Number(newNum) / Number(old)) * Number(100);
    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;

    console.log("Logic ", (Number(newNum) / Number(old)).toString())
    console.log("Old Number: ", Number(old));
    console.log("New Number: ", Number(newNum));
    console.log("Percentage: ", Number(percentage).toFixed(2));
    return (insureNumber(Number(percentage))).toFixed(2).replace(regexp, "") + "%";
}

const insureNumber = (num: number): number => {
    return isNaN(parseInt(num.toString())) ? 0 : Number(num);
}
export const numify = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}