import {createSlice} from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'user',
    initialState: {
        totalLength: BigInt(0),
        joinedToday: BigInt(0),
        online: BigInt(0),
        dailyOnline: BigInt(0),
        weeklyJoined: [],
        status: false,
    } as UserSliceType,
    reducers: {
        setUserLength: (state, action) => {
            state.totalLength = action.payload.total;
            state.joinedToday = action.payload.today;
            state.online = action.payload.online;
            state.dailyOnline = action.payload.dailyOnline;
            state.weeklyJoined = action.payload.weeklyJoined;
            state.status = true;
        }
    }
})


export const {setUserLength} = userSlice.actions;
export default userSlice.reducer;