import api from './core/api';

class AdminService {
    check() {
        return api.get('/admin/check');
    }
    get() {
        return api.get('/admin');
    }
    all() {
        return api.get('/admin/all');
    }
}

export default new AdminService();