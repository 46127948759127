// Chakra imports
import {Box, Flex, Text, Select, useColorModeValue} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import PieChart from 'components/charts/PieChart';
import {pieChartData, pieChartOptions} from 'variables/charts';
import {VSeparator} from 'components/separator/Separator';
import {useSelector} from "react-redux";
import {numify, percentage} from "../../../../helper/format.helper";
import {useEffect} from "react";

export default function Conversion(props: { [x: string]: any }) {
    const {...rest} = props;
    const coin: CoinSliceType = useSelector((state: any) => state.coin)

    // Chakra Color Mode
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const cardColor = useColorModeValue('white', 'navy.700');
    const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');

    const myPieChartOption: any = {
        labels: ['Mined', 'Burned'],
        colors: ['#00a207', '#ff6a6a'],
        chart: {
            width: '50px'
        },
        states: {
            hover: {
                filter: {
                    type: 'none'
                }
            }
        },
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        hover: {mode: null},
        plotOptions: {
            donut: {
                expandOnClick: false,
                donut: {
                    labels: {
                        show: false
                    }
                }
            }
        },
        fill: {
            colors: ['#6bf861', '#ff6a6a']
        },
        tooltip: {
            enabled: true,
            theme: 'dark',
            y: {
                formatter: (val: any) => {
                    return numify(val);
                }
            }
        }
    };
    let myPieChartData = [parseInt(coin.totalEarned.toString()), parseInt(coin.totalSpent.toString())]
    // alert([Number(coin.totalEarned), Number(coin.totalSpent)])
    const percentageMined = percentage(BigInt(coin.totalEarned) + BigInt(coin.totalSpent), BigInt(coin.totalEarned))
    const percentageBurned = percentage(BigInt(coin.totalEarned) + BigInt(coin.totalSpent), BigInt(coin.totalSpent))

    useEffect(() => {
         myPieChartData = [parseInt(coin.totalEarned.toString()), parseInt(coin.totalSpent.toString())]
    }, [coin.totalEarned, coin.totalSpent]);
    return (
        <Card p='20px' alignItems='center' flexDirection='column' w='100%' {...rest}>
            <Flex
                px={{base: '0px', '2xl': '10px'}}
                justifyContent='space-between'
                alignItems='center'
                w='100%'
                mb='8px'>
                <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
                    Dragon Coin
                </Text>
                {/*<Select fontSize='sm' variant='subtle' defaultValue='monthly' width='unset' fontWeight='700'>*/}
                {/*    <option value='daily'>Daily</option>*/}
                {/*    <option value='monthly'>Monthly</option>*/}
                {/*    <option value='yearly'>Yearly</option>*/}
                {/*</Select>*/}
            </Flex>

            {BigInt(coin.totalEarned) > 1 ?
                <PieChart h='100%' w='100%'  chartData={myPieChartData} chartOptions={myPieChartOption}/> : null}
            <Card
                bg={cardColor}
                flexDirection='row'
                justifyContent='space-evenly'
                boxShadow={cardShadow}
                w='100%'
                p='15px'
                px='20px'
                mt='15px'
                mx='auto'>
                <Flex direction='column' py='5px'>
                    <Flex align='center'>
                        <Box h='8px' w='8px' bg='brand.500' borderRadius='50%' me='4px'/>
                        <Text fontSize='xs' color='secondaryGray.600' fontWeight='700' mb='5px'>
                            Mined
                        </Text>
                    </Flex>
                    <Text fontSize='lg' color={textColor} fontWeight='700'>
                        {percentageMined}
                    </Text>
                </Flex>
                <VSeparator mx={{base: '60px', xl: '60px', '2xl': '60px'}}/>
                <Flex direction='column' py='5px' me='10px'>
                    <Flex align='center'>
                        <Box h='8px' w='8px' bg='#6AD2FF' borderRadius='50%' me='4px'/>
                        <Text fontSize='xs' color='secondaryGray.600' fontWeight='700' mb='5px'>
                            Burned
                        </Text>
                    </Flex>
                    <Text fontSize='lg' color={textColor} fontWeight='700'>
                        {percentageBurned}
                    </Text>
                </Flex>
            </Card>
        </Card>
    );
}
