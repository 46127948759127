import {createSlice} from "@reduxjs/toolkit";

const itemSlice = createSlice({
    name: 'item',
    initialState: {
        latestTransactions: [],

        boostLength: 0,
        dailyBoostLength: 0,
        leagueLength: 0,
        skinLength: 0,
        squadLength: 0,
    } as ItemSliceType,
    reducers: {
        setItemLength: (state, action) => {
            state.boostLength = action.payload.boost;
            state.dailyBoostLength = action.payload.dailyBoost;
            state.leagueLength = action.payload.league;
            state.skinLength = action.payload.skin;
            state.squadLength = action.payload.squad;
        },
        setLatestTransactions: (state, action) => {
            state.latestTransactions = action.payload;
        }
    }
})

export const {setItemLength, setLatestTransactions} = itemSlice.actions;
export default itemSlice.reducer;