import {createSlice} from "@reduxjs/toolkit";
import routes from "../routes";

const activeRouteSlice = createSlice({
    name: 'activeRoute',
    initialState: {
        route: '',
        navbar: '',
        navbarSecondary: false,
    },
    reducers: {
        refreshRoutes: (state, action) => {
            for (let i = 0; i < routes.length; i++) {
                console.log(action.payload + " = " + routes[i].layout + routes[i].path)
                if ([action.payload].indexOf(routes[i].layout + routes[i].path) !== -1) {
                    state.route = routes[i].name;
                    state.navbar = routes[i].name;
                    state.navbarSecondary = routes[i].secondary;
                    return;
                }
            }
        }
    }
})

export const {refreshRoutes} = activeRouteSlice.actions;
export default activeRouteSlice.reducer;