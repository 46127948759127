// Chakra imports
import {Box, Flex, Icon, Text, useColorModeValue} from '@chakra-ui/react';
import BarChart from 'components/charts/BarChart';

// Custom components
import Card from 'components/card/Card';
import {barChartDataDailyTraffic, barChartOptionsDailyTraffic} from 'variables/charts';

// Assets
import {RiArrowDownSFill, RiArrowUpSFill} from 'react-icons/ri';
import {useSelector} from "react-redux";
import {increasePercentage} from "../../../../helper/format.helper";

export default function DailyTraffic(props: { [x: string]: any }) {
    const user: UserSliceType = useSelector((state: any) => state.user);
    const {...rest} = props;
    const myBarChartOption = {
        chart: {
            toolbar: {
                show: false
            }
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            onDatasetHover: {
                style: {
                    fontSize: '12px',
                }
            },
            theme: 'dark'
        },
        xaxis: {
            categories: user.weeklyJoined.map((item: WeeklyJoinedUserType) => item.date.split('/')[1]),
            show: false,
            labels: {
                show: true,
                style: {
                    colors: '#A3AED0',
                    fontSize: '14px',
                    fontWeight: '500'
                }
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            show: false,
            color: 'black',
            labels: {
                show: true,
                style: {
                    colors: '#CBD5E0',
                    fontSize: '14px'
                }
            }
        },
        grid: {
            show: false,
            strokeDashArray: 5,
            yaxis: {
                lines: {
                    show: true
                }
            },
            xaxis: {
                lines: {
                    show: false
                }
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                type: 'vertical',
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                colorStops: [
                    [
                        {
                            offset: 0,
                            color: '#4318FF',
                            opacity: 1
                        },
                        {
                            offset: 100,
                            color: 'rgba(67, 24, 255, 1)',
                            opacity: 0.28
                        }
                    ]
                ]
            }
        },
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                columnWidth: '40px'
            }
        }
    };
    const myBarChartData: { name: string, data: number[] }[] = [{
        name: 'Users',
        data: user.weeklyJoined.map((item: WeeklyJoinedUserType) => item.count)
    }];
    const totalJoined = user.weeklyJoined.reduce((acc: number, item: WeeklyJoinedUserType) => acc + item.count, 0);
    const percentage = increasePercentage(BigInt(user.totalLength), BigInt(user.totalLength) + BigInt(totalJoined),false);
    // Chakra Color Mode
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    return (
        <Card alignItems='center' flexDirection='column' w='100%' {...rest}>
            <Flex justify='space-between' align='start' px='10px' pt='5px' w='100%'>
                <Flex flexDirection='column' align='start' me='20px'>
                    <Flex w='100%'>
                        <Text me='auto' color='secondaryGray.600' fontSize='sm' fontWeight='500'>
                            Weekly Joined
                        </Text>
                    </Flex>
                    <Flex align='end'>
                        <Text color={textColor} fontSize='34px' fontWeight='700' lineHeight='100%'>
                            {totalJoined}
                        </Text>
                        <Text ms='6px' color='secondaryGray.600' fontSize='sm' fontWeight='500'>
                            Joined
                        </Text>
                    </Flex>
                </Flex>
                <Flex align='center'>
                    <Icon as={percentage[0] == '-' ? RiArrowDownSFill : RiArrowUpSFill} color={percentage[0] == '-' ? 'red.500' : 'green.500'}/>
                    <Text color='green.500' fontSize='sm' fontWeight='700'>
                        {percentage[0] == '-' ? percentage.substring(1) : percentage}
                    </Text>
                </Flex>
            </Flex>
            <Box h='240px' mt='auto'>
                { user.weeklyJoined.length > 0 ? <BarChart chartData={myBarChartData} chartOptions={myBarChartOption}/> : null}
            </Box>
        </Card>
    );
}
