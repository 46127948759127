import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/App.css';
import {ChakraProvider, useColorMode} from '@chakra-ui/react';
import theme from './theme/theme';
import {Provider} from 'react-redux';
import {store} from "./store/store";
import App from "./App";
import setupInterceptors from "./service/core/setupInterceptors";
import {Toaster} from 'sonner';

(BigInt.prototype as any).toJSON = function () {
    return this.toString()
}
const ToasterTheme = () => {
    const {colorMode} = useColorMode()
    useEffect(() => {

    }, [colorMode]);
    return <Toaster
        position="top-right"
        expand={false}
        richColors={true}
        theme={useColorMode().colorMode === 'light' ? 'light' : 'dark'}
    />;

}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <Provider store={store}>
        <ChakraProvider theme={theme}>
            <App/>
            <ToasterTheme />
        </ChakraProvider>
    </Provider>,
)


setupInterceptors(store);