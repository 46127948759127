// Chakra imports
import {Portal, Box, useDisclosure} from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin';
import Sidebar from 'components/sidebar/Sidebar';
import {SidebarContext} from 'contexts/SidebarContext';
import {useEffect, useState} from 'react';
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom';
import routes from 'routes';
import UserReports from "../../views/admin/default";
import {useDispatch, useSelector} from "react-redux";
import {refreshRoutes} from "../../store/activeRouteSlice";
import AdminService from "../../service/admin.service";
import {setAccessCheck} from "../../store/authSlice";
import {setAdmin} from "../../store/adminSlice";
import UserService from "../../service/user.service";
import {setUserLength} from "../../store/userSlice";
import DashboardService from "../../service/dashboard.service";
import {setCoin} from "../../store/coinSlice";
import {toast} from "sonner";
import {setItemLength, setLatestTransactions} from "../../store/itemSlice";

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
    const auth = useSelector((state: any) => state.auth)
    const admin = useSelector((state: any) => state.admin)
    const user = useSelector((state: any) => state.user)
    const activeRoute = useSelector((state: any) => state.activeRoute)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {...rest} = props;
    // states and functions
    const [fixed] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    // functions for changing the states from components
    const getRoute = () => {
        return window.location.pathname !== '/admin/full-screen-maps';
    };
    document.documentElement.dir = 'ltr';
    const {onOpen} = useDisclosure();

    useEffect(() => {
        if (!auth.isAuth) {
            navigate('/auth/sign-in')
        } else {
            dispatch(refreshRoutes(window.location.pathname))
        }
    }, [auth.isAuth]);

    useEffect(() => {
        if (auth.accessCheck == null) {
            dispatch(setAccessCheck(setInterval(() => {
                console.log('Getting access')
                AdminService.check().then((response: any) => {
                }).catch((e: any) => {
                });
            }, 10000)))
        }
        if (!admin.status || !user.status) {
            DashboardService.all().then((response: any) => {
                const data: DashboardAPIType = response.data;
                console.log(data)
                dispatch(setAdmin(data.data.admin))
                dispatch(setUserLength(data.data.userLength))
                dispatch(setCoin(data.data.coin))
                console.log(data.data.coin)
                dispatch(setItemLength(data.data.itemLength))
                dispatch(setLatestTransactions(data.data.history.latestTransactions))
            }).catch((e: any) => {
                console.log("Error: ",e)
                if (e.response != undefined && e.response.status != 401 && e.response.status != 403)
                    toast.warning('Error fetching data, please refresh the page')
            });
        }
    }, []);
    return (
        <Box>
            <SidebarContext.Provider
                value={{
                    toggleSidebar,
                    setToggleSidebar
                }}>
                <Sidebar routes={routes} display='none' {...rest} />
                <Box
                    float='right'
                    minHeight='100vh'
                    height='100%'
                    overflow='auto'
                    position='relative'
                    maxHeight='100%'
                    w={{base: '100%', xl: 'calc( 100% - 290px )'}}
                    maxWidth={{base: '100%', xl: 'calc( 100% - 290px )'}}
                    transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
                    transitionDuration='.2s, .2s, .35s'
                    transitionProperty='top, bottom, width'
                    transitionTimingFunction='linear, linear, ease'>
                    <Portal>
                        <Box>
                            <Navbar
                                onOpen={onOpen}
                                logoText={'Horizon UI Dashboard PRO'}
                                brandText={activeRoute.route}
                                secondary={activeRoute.navbarSecondary}
                                message={activeRoute.navbar}
                                fixed={fixed}
                                {...rest}
                            />
                        </Box>
                    </Portal>
                    {getRoute() ? (
                        <Box mx='auto' p={{base: '20px', md: '30px'}} pe='20px' minH='100vh' pt='50px'>
                            <Outlet/>
                        </Box>
                    ) : null}
                    <Box>
                        <Footer/>
                    </Box>
                </Box>
            </SidebarContext.Provider>
        </Box>
    );
}
